@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: work-sans;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDp.woff2);
}

body {
  margin: 0;
  font-family: "Work Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-variation-settings-400 {
  font-variation-settings: "wght" 400;
}

.font-variation-settings-500 {
  font-variation-settings: "wght" 500;
}

.font-variation-settings-600 {
  font-variation-settings: "wght" 600;
}

.font-variation-settings-700 {
  font-variation-settings: "wght" 700;
}

.font-variation-settings-800 {
  font-variation-settings: "wght" 800;
}

.main {
  min-height: calc(100vh - 426px);
  color: #37393b !important;
  font-family: "Work Sans";
}

.shadow1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.hover-shadow1 {
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
}

.login-container {
  min-height: calc(100vh - 426px);
}

.Prepayment-Type-Container table p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}